import { Container, Row, Col } from "react-bootstrap";

export default function LinkSuccessPage() {
  return (
    <div className="page">
      <Container className="main">
        <Row className="pd-t">
          <Col md={12}>
            <div className="alert alert-success" role="alert">
              Thank you for submitting!<br />
              Expect to hear back from a funding manager soon.
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
