import { Navbar, Container } from "react-bootstrap";

export default function NavBar() {

  return (   
      <Navbar expand="lg">
        <Container className="navbar-container">
          <Navbar.Brand href="https://bigthinkcapital.com/">
            <img src="/logo.png" width="182" height="75" alt="Big Think Capital"/>
          </Navbar.Brand>
        </Container>
      </Navbar>
  );
}