import { AxiosResponse } from "axios";
import http from "../services/httpService";

const createLinkToken = (contactID: string, verifyField: string) => http.post<any>("/plaid/create-link-token", {contactID: contactID, verifyField: verifyField});

const exchangePublicToken = (publicToken: string) => http.post<{accessToken: string, itemID: string}>("/plaid/exchange-public-token", {publicToken: publicToken});

const generateAssetReport = (accessToken: string, contactID: string) => http.post<AxiosResponse>("/plaid/generate-asset-report", {accessToken: accessToken, contactID: contactID});

const verifySecurityField = (contactID: string, verifyField: string) => http.post<any>("/centrex/verify-security-field", {contactID: contactID, verifyField: verifyField});

export {
  createLinkToken,
  exchangePublicToken,
  generateAssetReport,
  verifySecurityField
};