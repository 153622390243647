import { useEffect, useCallback, useState } from "react";
import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnExit,
  PlaidLinkOptions,
} from "react-plaid-link";
import { createLinkToken, exchangePublicToken } from "../../api";
import { Button, Container } from "react-bootstrap";
import { useAppDispatch } from "../../hooks/redux";
import { setAccessToken, setItemID } from "src/redux/reducers/plaidLinkSlice";
import Spinner from "react-bootstrap/Spinner";

export interface IProps {
  contactID: string;
  verifyField: string;
}

const PlaidLink = ({ contactID, verifyField }: IProps) => {
  const [token, setToken] = useState<string | null>(null);
  const env = "production";
  const dispatch = useAppDispatch();

  // get a linkToken from the API when component mounts
  useEffect(() => {
    const getLinkToken = async () => {
      try {
        const response = await createLinkToken(contactID, verifyField);
        const { linkToken } = response.data;
        setToken(linkToken.link_token);
      } catch (err) {
        console.log(err);
      }
    };
    getLinkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
    const getExchangePublicToken = async () => {
      // send public_token to server
      // console.log("[onSuccess]", publicToken);
      const response = await exchangePublicToken(publicToken);
      const { accessToken, itemID } = response.data;
      dispatch(setAccessToken(accessToken));
      dispatch(setItemID(itemID));
    };
    getExchangePublicToken();
  }, [dispatch]);

  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // console.log("[onExit]", error);
  }, []);

  const config: PlaidLinkOptions = {
    token,
    env,
    onSuccess,
    onExit,
  };

  const {
    open,
    ready,
  } = usePlaidLink(config);

  return (
    <Container className="jumbotron">
      {!ready ?
        <Container>
          <h5 id="loading-p">Establishing a secure connection</h5>
          <Spinner animation="border" variant="secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Container>
        :
        <Button id="btc-btn" onClick={() => open()} disabled={!ready}>
          Connect A Bank Account
        </Button>}
    </Container>
  );
};

export default PlaidLink;