import { Routes, Route } from "react-router-dom";
import { HomePage, LinkSuccessPage, NotFoundPage } from "./pages";
import { NavBar, Footer } from "./components";

export default function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path='/link/:userid' element={<HomePage />} />
        <Route path="/link/success" element={<LinkSuccessPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer/>
    </>
  );
}