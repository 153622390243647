import { FormEvent, useEffect, useState } from "react";
import { Container, Card, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Error, PlaidLinkConnect } from "../components";
import { generateAssetReport, verifySecurityField } from "../api";
import { useAppSelector } from "../hooks/redux";
import { useServerError } from "../hooks/useServerError";
import { AxiosError } from "axios";

export default function Home() {
  const plaidAccessToken = useAppSelector((state) => state.plaidLink.accessToken);
  const { userid } = useParams<{ userid: string }>();
  const [securityChecked, setSecurityChecked] = useState(false);
  const [securityCheckField, setSecurityCheckField] = useState("");
  const { serverError, handleServerError, resetServerError } = useServerError();
  let navigate = useNavigate();

  // Once the access token has been received, trigger generation of the asset report
  useEffect(() => {
    const triggerGenerateAssetReport = async () => {
      if (userid && plaidAccessToken.length > 0) {
        const response = await generateAssetReport(plaidAccessToken, userid).catch(handleServerError);
        if (response && response.status === 200)
          navigate("/link/success");
      }
    };
    triggerGenerateAssetReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plaidAccessToken, userid]);

  const submitFormData = (e: FormEvent) => {
    e.preventDefault();
  };

  const submitVerifyForm = async () => {
    verifySecurityField(userid ?? "", securityCheckField)
      .then((res) => { resetServerError(); setSecurityChecked(true); })
      .catch((err: AxiosError<any, any>) => { handleServerError(err); setSecurityCheckField(""); });
  }

  return (
    <div className="page">
      <Container className="main">
        {serverError &&
          <Error>
              {serverError}
          </Error>}
        {!securityChecked ?
          <Container>
            <div><h5>Please verify your last name below.</h5></div>
            <Card id="form-card">
              <Card.Body>
                <Form onSubmit={submitFormData} autoComplete="off">
                  <Form.Group>
                    <Form.Control
                      id="form-step"
                      className="shadow-none"
                      defaultValue=""
                      type="text"
                      placeholder="Last Name"
                      onChange={e => setSecurityCheckField(e.target.value)}
                      onKeyDown={async e => { if (e.key === "Enter") await submitVerifyForm(); }}
                    />
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
            <Button className="shadow-none" id="btc-btn" onClick={async () => await submitVerifyForm()}>Submit</Button>
          </Container>
          : <></>}
        {plaidAccessToken === "" && userid && securityChecked && securityCheckField !== "" ?
          <PlaidLinkConnect contactID={userid} verifyField={securityCheckField} />
          : <></>}
      </Container>
    </div>
  );
}
