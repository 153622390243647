import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface PlaidLinkState {
  itemID: string;
  accessToken: string;
};

const initialState: PlaidLinkState = {
  itemID: "",
  accessToken: ""
};

export const plaidLinkSlice = createSlice({
  name: "plaidLink",
  initialState,
  reducers: {
    setItemID: (state, action) => {
      state.itemID = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
  },
});

export const { setItemID, setAccessToken } = plaidLinkSlice.actions;

export const selectItemID = (state: RootState) => state.plaidLink.itemID;
export const selectAccessToken = (state: RootState) => state.plaidLink.accessToken;

export default plaidLinkSlice.reducer;