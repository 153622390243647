import { Container, Row, Col } from "react-bootstrap";

export default function NotFoundPage() {
  return (
    <div className="page">
      <Container className="main">
        <Row className="pd-t">
          <Col md={12}>
            <b>Page Not Found</b><br/>
            If you believe you have reached this page in error, please contact us at support@bigthinkcapital.com
          </Col>
        </Row>
      </Container>
    </div>
  );
}
