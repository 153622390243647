import { AxiosError } from "axios";
import { useState } from "react";

export const useServerError = () => {
  const [serverError, setServerError] = useState<string | null>(null);

  const handleServerError = (error: AxiosError<any>) => {
    if (error.response) {
      if(!error.response.data || !error.response.data.message)
        setServerError("Error establishing connection to Big Think Capital. Please contact us if this issue persists. We apologize for the inconvenience and hope to resolve the issue soon.");
      else
        setServerError(error.response.data.message);
    }
  };

  const resetServerError = () => {
    setServerError(null);
  };

  return { serverError, handleServerError, resetServerError };
};