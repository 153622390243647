import { Container } from "react-bootstrap";

export default function Footer() {

  return (   
      <Container>
        <hr/>
        <br/>
        <p className="text-sm">
          © Copyright 2022 | Big Think Capital, All Rights Reserved. 
          <a id="footer-link" href="https://bigthinkcapital.com/terms-of-use/" target="_blank" rel="noreferrer">Terms & Conditions</a>, 
          <a id="footer-link" href="https://bigthinkcapital.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
        </p>
        <p className="text-sm">
          Disclaimer: The above information is provided as a guideline. 
          Some loan conditions may fall outside of these parameters. 
          We recommend that you speak with one of our advisors before taking any course of action based on this information.
        </p>
      </Container>
  );
}